import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';

import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-quiz-edit-dialog',
  templateUrl: './quiz-edit-dialog.component.html',
  styleUrls: ['./quiz-edit-dialog.component.scss']
})
export class QuizEditDialogComponent implements OnInit {
  @ViewChild('dialogContent') dialogContent: ElementRef;
  questions:any;
  newQuizTitle:string;
  newQuizTime:string;
user:User;
correctOptionA:any=false;
correctOptionB:any=false;
correctOptionC:any=false;
correctOptionD:any=false;
correctOption:string
isAdding:boolean=false;
isUpdating:boolean=false;
isChangingTitle=false;
isChangingTime=false;
selectedQuestion:any;
title:string;
titleA;
titleB;
titleC;
titleD;
options = [
  {'text': 'sssdada', 'title': 'A'},
  {'text': 'sdadswwwwwww', 'title': 'B'},
  {'text': 'fgf', 'title': 'C'},
  {'text': 'sssdada', 'title': 'D'}
];

  constructor(@Inject(MAT_DIALOG_DATA) public data :any,private mainService: MainService,private authService:AuthService,  private _snackBar:MatSnackBar,) {
    this.authService.currentUser.subscribe((user: User) => {
      this.user = user;

    });
   }

  ngOnInit(): void {
    this.getQuestions()
  }
  updateQuestion(){
    this.options[0].text = this.titleA
    this.options[1].text = this.titleB
    this.options[2].text = this.titleC
    this.options[3].text = this.titleD
    console.log(JSON.stringify(this.options))
    const data={
      "operation_type":"update",
      "service_type":"update_quiz_question",
      "question_id":this.selectedQuestion.id,
      "title":this.title,
      "options":JSON.stringify(this.options),
      "quiz_id":this.data.id,
      "correct_option":this.correctOption

    }
    this.mainService.updateQuizQuestion(data).subscribe(res=>{
console.log(res)
if(res == true){
  this.openSnackBar('Soru Başarıyla Güncellendi.')
  this.getQuestions();
  this.isUpdating= false
}else{
  this.openSnackBar('Bir Hata Oluştu.')

}
    });
  }
  changeUpdating(question=null){
    this.isUpdating=!this.isUpdating;
    this.selectedQuestion=question
    console.log(this.selectedQuestion)
    this.title=this.selectedQuestion.title
    this.titleA=this.selectedQuestion.options[0].text
    this.titleB=this.selectedQuestion.options[1].text
    this.titleC=this.selectedQuestion.options[2].text
    this.titleD=this.selectedQuestion.options[3].text

    this.dialogContent.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

  }
  selectOption(option:string){

    if (option == 'A' && this.correctOptionA == true){
      this.correctOptionA = true
      this.correctOptionB = false;
      this.correctOptionC = false;
      this.correctOptionD = false;

    }
    else if (option == 'B' && this.correctOptionB == true){
      this.correctOptionB = true
      this.correctOptionA = false;
      this.correctOptionC = false;
      this.correctOptionD = false;

    }
    else if (option == 'C' && this.correctOptionC == true){
      this.correctOptionC = true
      this.correctOptionA = false;
      this.correctOptionB = false;
      this.correctOptionD = false;

    }
    else if (option == 'D' && this.correctOptionD == true){
      this.correctOptionD = true
      this.correctOptionA = false;
      this.correctOptionB = false;
      this.correctOptionC = false;

    }
    switch (option) {
      case 'A':
        this.correctOption = "A"
        break;
        case 'B':
          this.correctOption = "B"
          break;
          case 'C':
        this.correctOption = "C"
        break;
        case 'D':
          this.correctOption = "D"
          break;

      default:
        break;
    }
    console.log(this.correctOption)

    }
    addNewQuestion(title:string,correct_option:string,titleA,titleB,titleC,titleD){
      this.options[0].text = titleA
      this.options[1].text = titleB
      this.options[2].text = titleC
      this.options[3].text = titleD

      console.log( this.options)

      const data = {

          "operation_type":"insert",
          "service_type":"add_quiz_question",
          "title":title,
          "options":JSON.stringify(this.options),
          "quiz_id":this.data.id,
          "correct_option":correct_option


      }
      console.log(data)
      this.mainService.addNewQuestionToQuiz(data).subscribe(res=>{
        console.log(res)
        if(res == true){
          this.getQuestions()
          this.changeAdding()
        this.openSnackBar('Soru başarıyla eklendi.')
        this.clearOptions()

        }
      })
    }
  clearOptions(){
    this.correctOptionA = false
    this.correctOptionB = false;
    this.correctOptionC = false;
    this.correctOptionD = false;
    this.title = null
    this.titleA =null
    this.titleB = null
    this.titleC = null
    this.titleD = null

  }
  changeAdding(){
    this.isAdding=!this.isAdding;
    this.clearOptions()
  }
  changeChangingTitle(){
    this.isChangingTitle=!this.isChangingTitle;

  }
  changeChangingTime(){
    this.isChangingTime=!this.isChangingTime;

  }
  openSnackBar(message:string) {
    this._snackBar.open(message,'Tamam', {
      duration: 5 * 1000,

      panelClass: ['green-snackbar', 'login-snackbar']
          });
  }
  deleteQuestion(question_id:number){
    const data = {

      "operation_type":"delete",
      "service_type":"delete_quiz_question",
      "question_id":question_id,
      "quiz_id":this.data.id
  }
  this.mainService.deleteQuestion(data).subscribe(res=>{
    console.log(res)
    if (res == true) {
      this.getQuestions()
      this.openSnackBar('Soru başarıyla silindi.')
    }
  })
  }
  async getQuestions(){
    const data = {
      "operation_type":"select",
      "service_type":"quiz_questions",
      "quiz_id":this.data.id
      }


      this.mainService.getQuizQuestions(data).subscribe(async res => {

  this.questions =await res
  console.log(this.questions);

   for (let i = 0; i < this.questions.length; i++) {

  this.questions[i].options =await JSON.parse(this.questions[i].options);

  }


      });
  }


  updateQuizTitle(title:string){
    const data = {
      "operation_type":"update",
      "service_type":"quiz_title",
      "title":title,
      "quiz_id":this.data.id

    }

   this.mainService.updateQuizTitle(data).subscribe(res=>{
      console.log(res)
      if(res == true){
        this.openSnackBar('Quiz Başlığı başarıyla Değiştirildi.')
        this.data.title = this.newQuizTitle
        this.changeChangingTitle()
        this.newQuizTitle = null
      }

   })
  }
  updateQuizTime(){

    const data = {

      "operation_type":"update",
      "service_type":"quiz_time",
      "time":this.newQuizTime,
      "quiz_id":this.data.id

    }
    this.mainService.updateQuizTime(data).subscribe(res=>{
      console.log(res);
      if (res != false){
        this.data.time = res

        this.openSnackBar('Quizin süresi güncellendi')
        this.changeChangingTime()

      }
      else {
        this.openSnackBar('Bir Hata Oluştu')
      }
    })
}
}
