import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-quiz-results-dialog',
  templateUrl: './quiz-results-dialog.component.html',
  styleUrls: ['./quiz-results-dialog.component.scss']
})
export class QuizResultsDialogComponent implements OnInit {
questions:any;
answers:any;
isLoading=false
  constructor(@Inject(MAT_DIALOG_DATA) public data :any,private mainService:MainService) { }
  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}
  async ngOnInit(): Promise<void> {
this.isLoading=true

    console.log(this.data)
    console.log(this.data.quiz_id)
    this.getQuestions()
   await this.delay(2000)

    this.getAnswers()
  }
getQuestions(){
  const data = {
    "operation_type":"select",
    "service_type":"quiz_questions",
    "quiz_id":this.data.quiz_id
  }
  this.mainService.getQuizQuestions(data).subscribe(res=>{
    console.log(res)
    this.questions = res
  })
}
getAnswers(){
  const data = {
    "operation_type":"select",
    "service_type":"quiz_answers",
    "quiz_id":this.data.quiz_id
  }
  this.mainService.getQuizAnswers(data).subscribe(async res=>{
    console.log(res)
    this.answers = res

this.isLoading=false

  })
}



calcAnswerStats(user_id) {
  let answers = this.answers;
  let questions = this.questions;
  let total = 0;
  let correct = 0;
  let incorrect = 0;
  let empty = 0;

  for (let index = 0; index < answers.length; index++) {
    const answer = answers[index];
    if (answer.user_id == user_id) {
      const question = questions.find(q => q.id === answer.question_id);
      if (question) {
        total++;
        if (answer.user_answer == question.correct_option) {
          correct++;
        } else if (answer.user_answer === null) {
          empty++;
        } else {
          incorrect++;
        }
      }
    }
  }

  return {
    total: total,
    correct: correct,
    incorrect: incorrect,
    empty: questions.length - (correct + incorrect),
    point: correct * (100 / questions.length)
  };
}

sortStudentsByPoint(data: any[]): any[] {
  return data.sort((a, b) => {
    const pointA = this.calcAnswerStats(a.id).point;
    const pointB = this.calcAnswerStats(b.id).point;
    return pointB - pointA;
  });
}
}
