import { Component, ElementRef, Inject, OnInit, ViewChild, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-quiz-dialog-component',
  templateUrl: './quiz-create-dialog-component.component.html',
  styleUrls: ['./quiz-create-dialog-component.component.scss'],
})
export class QuizCreateDialogComponentComponent implements OnInit {
  @ViewChild('dialogContent') dialogContent: ElementRef;

  disableSelect = new FormControl(false);
quiz:any
user:User
isResolved:boolean;
currentDateTime: Date;
formattedDate:string;
isLoading=false;
isUpdating
isResult ;
questions:any;
answers:any;
trueCount:any=0;
falseCount:any=0;
emptyCount:any=0;
date:any;
newQuizTitle:string;
newQuizTime:string;
isAdding:boolean=false;
isChangingTitle=true;
isChangingTime=true;
correctOptionA:any=false;
correctOptionB:any=false;
correctOptionC:any=false;
correctOptionD:any=false;
correctOption:string
selectedEducationId;
educations: any[] = [];
selectedQuestion
selectedQuestionIndex
isDone=false;
title:any;
quiz_title:any;
quiz_time:any;
titleA;
titleB;
titleC;
titleD;

options = [
  {'text': 'sssdada', 'title': 'A'},
  {'text': 'sdadswwwwwww', 'title': 'B'},
  {'text': 'fgf', 'title': 'C'},
  {'text': 'sssdada', 'title': 'D'}
];
delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
async getQuestions(){
const data = {
  "operation_type":"select",
  "service_type":"quiz_questions",
  "quiz_id":this.quiz.id
  }


  this.mainService.getQuizQuestions(data).subscribe(async res => {

this.questions =await res

for (let i = 0; i < this.questions.length; i++) {

this.questions[i].options =await JSON.parse(this.questions[i].options);
}





  });
}




constructor(
  private route: ActivatedRoute,

  @Inject(MAT_DIALOG_DATA) public data :any,private mainService: MainService,private authService:AuthService,  private _snackBar:MatSnackBar,public dialogRef: MatDialogRef<QuizCreateDialogComponentComponent>) {
  this.authService.currentUser.subscribe((user: User) => {
    this.user = user;

  });
 }
 updateQuestion(){
  this.options[0].text = this.titleA
  this.options[1].text = this.titleB
  this.options[2].text = this.titleC
  this.options[3].text = this.titleD
  const data={
    "operation_type":"update",
    "service_type":"update_quiz_question",
    "question_id":this.selectedQuestion.id,
    "title":this.title,
    "options":JSON.stringify(this.options),
    "quiz_id":this.quiz.id,
    "correct_option":this.correctOption

  }
  this.mainService.updateQuizQuestion(data).subscribe(res=>{
if(res == true){
this.openSnackBar('Soru Başarıyla Güncellendi.')
this.getQuestions();
this.isUpdating= false
}else{
this.openSnackBar('Bir Hata Oluştu.')

}
  });
}
selectOption(option:string){

  if (option == 'A' && this.correctOptionA == true){
    this.correctOptionA = true
    this.correctOptionB = false;
    this.correctOptionC = false;
    this.correctOptionD = false;

  }
  else if (option == 'B' && this.correctOptionB == true){
    this.correctOptionB = true
    this.correctOptionA = false;
    this.correctOptionC = false;
    this.correctOptionD = false;

  }
  else if (option == 'C' && this.correctOptionC == true){
    this.correctOptionC = true
    this.correctOptionA = false;
    this.correctOptionB = false;
    this.correctOptionD = false;

  }
  else if (option == 'D' && this.correctOptionD == true){
    this.correctOptionD = true
    this.correctOptionA = false;
    this.correctOptionB = false;
    this.correctOptionC = false;

  }
  switch (option) {
    case 'A':
      this.correctOption = "A"
      break;
      case 'B':
        this.correctOption = "B"
        break;
        case 'C':
      this.correctOption = "C"
      break;
      case 'D':
        this.correctOption = "D"
        break;

    default:
      break;
  }

  }
  addNewQuestion(title:string,correct_option:string,titleA,titleB,titleC,titleD){
    this.options[0].text = titleA
    this.options[1].text = titleB
    this.options[2].text = titleC
    this.options[3].text = titleD



    const data = {

        "operation_type":"insert",
        "service_type":"add_quiz_question",
        "title":title,
        "options":JSON.stringify(this.options),
        "quiz_id":this.quiz.id,
        "correct_option":correct_option


    }

    this.mainService.addNewQuestionToQuiz(data).subscribe(res=>{

      if(res == true){
        this.getQuestions()
        this.changeAdding()
      this.openSnackBar('Soru başarıyla eklendi.')
      this.clearOptions()

      }
    })
  }
  changeUpdating(question=null,index=null){
    this.selectedQuestionIndex=index
    this.isUpdating=!this.isUpdating;
    this.selectedQuestion=question
    this.title=this.selectedQuestion.title
    this.titleA=this.selectedQuestion.options[0].text
    this.titleB=this.selectedQuestion.options[1].text
    this.titleC=this.selectedQuestion.options[2].text
    this.titleD=this.selectedQuestion.options[3].text

    this.dialogContent.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

  }
deleteQuestion(question_id:number){
  const data = {

    "operation_type":"delete",
    "service_type":"delete_quiz_question",
    "question_id":question_id,
    "quiz_id":this.quiz.id
}
this.mainService.deleteQuestion(data).subscribe(res=>{
  if (res == true) {
    this.getQuestions()
    this.openSnackBar('Soru başarıyla silindi.')
  }
})
}
openSnackBar(message:string) {
  this._snackBar.open(message,'Tamam', {
    duration: 5 * 1000,

    panelClass: ['green-snackbar', 'login-snackbar']
        });
}

clearOptions(){
  this.correctOptionA = false
  this.correctOptionB = false;
  this.correctOptionC = false;
  this.correctOptionD = false;
this.title=null
  this.titleA =null
  this.titleB = null
  this.titleC = null
  this.titleD = null

}
changeAdding(){
  this.isAdding=!this.isAdding;
  this.clearOptions()
}


updateQuizInfo(){
  const data = {

    "operation_type":"update",
    "service_type":"quiz_info",
    "time":this.newQuizTime,
    "title":this.newQuizTitle,
    "education_id":this.selectedEducationId,
    "quiz_id":this.quiz.id

  }
  this.mainService.updateQuizInfo(data).subscribe(res=>{})
}
saveQuiz(){



  if (this.isDone){
    this.dialogRef.close();
  }
  else {

this.createQuiz()

  }

}

test(){

}

createQuiz(){
  const data = {
    "operation_type":"insert",
    "service_type":"create_quiz",
    "teacher_id":this.user.id,
    "time":this.newQuizTime,
    "title":this.newQuizTitle,
    "education_id":this.selectedEducationId,
    "end_date":this.date



  }
  this.mainService.createQuiz(data).subscribe(res=>{
    this.quiz=res[0];
    this.isDone = true
    this.openSnackBar('Quiz Başarıyla Oluşturuldu.')
  })
}
  ngOnInit(): void {




  }


}
