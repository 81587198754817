<h2 mat-dialog-title>Quiz Ekle</h2>
<div mat-dialog-content>
  <div mat-form-field class="example-form-field" appearance="fill">
    <div mat-label class="mat-label">Quiz Başlığı</div>
    <input matInput type="text" [(ngModel)]="title" class="mat-input">

  </div>

  <!-- <div mat-form-field class="example-form-field" appearance="fill">
    <div mat-label class="mat-label">Google Form Linki</div>
    <input matInput type="text" [(ngModel)]="url" class="mat-input">

  </div> -->

  <div mat-form-field appearance="fill" class="select">
    <div mat-label class="mat-label">Quizi Eklemek İstediğiniz Eğitim</div>
    <select [(ngModel)]="lesson" chan matNativeControl
      [disabled]="disableSelect.value" class="mat-select">
      <option value="" selected></option>
      <option class="option" value="{{lesson.id}}" *ngFor="let lesson of
        lessons"><div>{{lesson.name }} </div>  <div>({{ lesson.start_date
          | slice:0:10}})</div></option>

    </select>
  </div>
  <div mat-form-field class="example-form-field" appearance="fill">
    <div mat-label class="mat-label">Süre (dk)</div>
    <input matInput type="number" [(ngModel)]="quiz_time" class="mat-input">

  </div>



  <div class="mat-buttons">
    <!-- <button mat-button (click)="openNewForm()" class="mat-button">Google Form
      Oluştur</button> -->
    <button mat-button mat-dialog-close class="mat-button">Vazgeç</button>
    <button mat-button  mat-dialog-close (click)="addQuiz()" class="mat-button"
      [disabled]="!title||!lesson||!quiz_time">Ekle</button>


  </div>

  <br><br><br>


</div>
