import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { RunScriptsDirective } from './run-scripts.directive';

@NgModule({
  imports: [
  ],
  declarations: [
    SafePipe,
    RunScriptsDirective,
  ],
  exports: [
    SafePipe,
    RunScriptsDirective
  ]
})
export class PipesModule { }
