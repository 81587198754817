import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor() { }

  getToken(): String{
    return window.localStorage['_token'];
  }

  saveToken(token: String) {
    window.localStorage['_token'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('_token');
  }
}
