import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url = 'https://api.ecodation.com/service.php';

  fileUploadUrl = 'https://api.ecodation.com/reklam_excel/main.php';
  contractUrl = 'https://api.ecodation.com/contracts/main.php';

  // purchase_url = 'https://ecodation.com/eco/iyzico/initialize_threeds.php';

  /*   private httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html',
        'Content-Type': 'application/json'
      }),
      responseType: 'text'
    }; */

  constructor(
    private http: HttpClient
  ) { }

  post(body: Object = {},type?): Observable<any> {
    return type=='web'? this.http.post(this.url+'?type=web', body): this.http.post(this.url, body);
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('fileKey', file, file.name);
    return this.http.post(this.fileUploadUrl, formData);
  }

  insertContract(formData: FormData) {
    return this.http.post(this.contractUrl, formData);

  }

  /*   purchase(body: Object = {}): Observable<any> {
      return this.http.post(this.purchase_url, body);
    } */
}
