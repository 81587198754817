import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MainService } from '../../core/services/main.service';
import { AuthService } from '../../core/services/auth.service';
import { User } from 'src/app/core/models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss']
})
export class AddDialogComponent implements OnInit {
title;
url;
user:any;
lesson;
quiz_time:any;
isLoading:boolean=false;

lessons:any;
  constructor(private mainService:MainService,private authService:AuthService,
    private _snackBar:MatSnackBar,

    ) { }
  disableSelect = new FormControl(false);
  openSnackBar() {
    this._snackBar.open('Quiz Başarıyla Eklendi','Tamam', {
      duration: 5 * 1000,
      panelClass: ['green-snackbar', 'login-snackbar']
          });
  }
  ngOnInit(): void {
    this.authService.currentUser.subscribe((user: User) => {
      this.user = user;
      console.log(this.user);
    });
    this.getLessonsByLecture()
  }
  openNewForm(){
    window.open('https://docs.google.com/forms/u/0/?tgif=d','_blank')
  }
  getLessonsByLecture(){
    const data ={
      "operation_type":"select",
      "service_type":"getLessonsByLecture",
      "teacher_id":this.user.id
    }
this.mainService.getLessonsByLecturer(data).subscribe(res=>{
  this.lessons=res.results;


});
  }

 addQuiz(){
  this.isLoading=true;
  const data={
    "operation_type":"insert",
    "service_type":"add_quiz",
    "title":this.title,
    "time":this.quiz_time,
    "education_id":parseInt( this.lesson),
    "teacher_id":parseInt( this.user.id)
  }
  this.mainService.addQuiz(data).subscribe(res=>{
    console.log(res)
this.isLoading=false;
this.openSnackBar();

  });
 }
}
