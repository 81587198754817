import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { NgxMaskModule, IConfig } from 'ngx-mask'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { HomeComponent } from './pages/home/home.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './core/interceptors/http.token.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

import { SharedModule } from './shared/shared.module';
import { PipesModule } from './core/pipes/pipes.module';

// DIL PAKETI DEGISTIRME
import localeTr from '@angular/common/locales/tr';
import { DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from './pages/dialog/dialog.component';
import { AddDialogComponent } from './pages/add-dialog/add-dialog.component';
import { AddHomeworkDialogComponent } from './add-homework-dialog/add-homework-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { QuizDialogComponentComponent } from './pages/quiz-dialog-component/quiz-dialog-component.component';
import { MatIconModule } from '@angular/material/icon';
import { QuizEditDialogComponent } from './pages/quiz-edit-dialog/quiz-edit-dialog.component';
import { QuizResultsDialogComponent } from './pages/quiz-results-dialog/quiz-results-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { QuizCreateDialogComponentComponent } from './pages/quiz-create-dialog-component/quiz-create-dialog-component.component';



registerLocaleData(localeTr, 'tr');

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddDialogComponent,
    AddHomeworkDialogComponent,
 DialogComponent,
 QuizDialogComponentComponent,
 QuizEditDialogComponent,
 QuizResultsDialogComponent,
 QuizCreateDialogComponentComponent


  ],
  entryComponents:[DialogComponent,AddDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatButtonModule,
    CarouselModule,
    MatSnackBarModule,
    MatRadioModule,
    SharedModule,
    PipesModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(options),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    FormsModule,
    MatDialogModule,
    MatDatepickerModule,
    NgxMatNativeDateModule,
    MatSnackBarModule,
    MatIconModule,
    MatTableModule



  ],
  exports:[
    HeaderComponent,
    FooterComponent,

  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'tr-TR' } // DIL PAKETI DEGISTIRME
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
