import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { HomeResolverService } from './core/resolvers/home-resolver.service';


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    resolve: { educations: HomeResolverService }
  },
  {
    path: 'education',
    loadChildren: () => import('./pages/education/education.module').then(m => m.EducationModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: 'password-reminder',
    loadChildren: () => import('./pages/auth/password-reminder/password-reminder.module').then(m => m.PasswordReminderModule)
  },
  {
    path: 'taken-educations',
    loadChildren: () => import('./pages/taken-educations/taken-educations.module').then(m => m.TakenEducationsModule)
  },
  /* {
    path: 'purchase',
    loadChildren: () => import('./pages/purchase/purchase.module').then(m => m.PurchaseModule)
  }, */
  {
    path: 'application-form',
    loadChildren: () => import('./pages/application-form/application-form.module').then(m => m.ApplicationFormModule)
  },
  {
    path: 'panel',
    loadChildren: () => import('./pages/panel/panel.module').then(m => m.PanelModule)
  },
  {
    path: 'contract',
    loadChildren: () => import('./pages/contract/contract.module').then(m => m.ContractModule)
  },
  {
    path: 'sertifika',
    loadChildren: () => import('./pages/sertifika/sertifika.module').then(m => m.SertifikaModule)
  },
  {
    path: 'rapor',
    loadChildren: () => import('./pages/rapor/rapor.module').then(m => m.RaporModule)
  },

  {
    path: 'installment',
    loadChildren: () => import('./pages/installment/installment.module').then(m => m.InstallmentModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
