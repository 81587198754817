<app-header></app-header>

<div class="definition">
  Çağı Yakala,<br>
  İstediğin Zaman, İstediğin Yerde<br>
  <span id="typewriter" data-array="" style="color: orange;">_</span> Eğitimi Alabilirsin
</div>

<div class="title">
  <div class="title-text">
    Bu ay <span>32.457 kişi</span> uzaktan eğitime katıldı
  </div>
</div>

<div class="attendees">
  <div class="attendees-images">
    <div class="image">
      <img src="https://api.ecodation.com/egitime_katilanlar/1.jpg">
    </div>
    <div class="image">
      <img src="https://api.ecodation.com/egitime_katilanlar/2.jpg">
    </div>
    <div class="image">
      <img src="https://api.ecodation.com/egitime_katilanlar/3.jpg">
    </div>
    <div class="image">
      <img src="https://api.ecodation.com/egitime_katilanlar/4.jpg">
    </div>
    <div class="image">
      <img src="https://api.ecodation.com/egitime_katilanlar/5.jpg">
    </div>
    <div class="image">
      <img src="https://api.ecodation.com/egitime_katilanlar/6.jpg">
    </div>
    <div class="image">
      <img src="https://api.ecodation.com/egitime_katilanlar/7.jpg">
    </div>
  </div>
</div>

<div class="badge">
  Eğitim
</div>

<div class="title">
  <div class="title-text">
    Aktif <span>Canlı Eğitimler</span>
  </div>
  <div class="title-description">
    Hızlıca başvuru yapabileceğiniz eğitimlerimiz
  </div>
</div>

<div class="arrow-down">
  <svg class="icon icon-arrow-down2" [@fade]="state" (@fade.done)="onDone($event)">
    <use xlink:href="assets/symbol-defs.svg#icon-arrow-down2"></use>
  </svg>
</div>

<owl-carousel-o [options]="educationOptions">
  <ng-template carouselSlide *ngFor="let edu of educations">
    <div class="education-card">
      <div class="education-card-title">
        {{edu.name }}
      </div>
      <div class="education-card-description">
        {{ edu.description }}
      </div>
      <div class="info">
        <div class="info-photo">
          <img [src]="edu.teacher_photo">
        </div>
        <div class="info-attributes">
          <div class="info-attributes-lecturer">
            <div class="name">
              {{ edu.teacher_name }}
            </div>
            <div class="company">
              {{ edu.teacher_title == '' ? edu.teacher_company : edu.teacher_title }}
            </div>
          </div>
          <div class="info-attributes-education">
            <div class="info-attributes-education-property">{{ edu.duration }} Saat</div>
            <div class="info-attributes-education-property">{{ edu.capacity }} Kişilik Kontenjan</div>
            <div class="info-attributes-education-property">Staj Imkani</div>
            <div class="info-attributes-education-property">Sertifikalı Eğitim</div>
          </div>
        </div>
      </div>
      <div class="duration">
        <span>{{ edu.duration }} saat</span> - Canlı Eğitim
      </div>
      <div class="timeleft">
        <span> {{ edu.startDate | date: 'd MMMM y'}} </span>'de Başlıyor !
      </div>
      <div class="inspect">
        <button [routerLink]="['/education', edu.slug]" mat-raised-button>İncele</button>
      </div>
    </div>
  </ng-template>
</owl-carousel-o>


<!-- <div class="badge">
  Eğitim
</div>

<div class="title">
  <div class="title-text">
    İşe Giren <span>Öğrencilerimiz</span>
  </div>
  <div class="title-description">
    Kendilerine güvendiler, başarılı oldular
  </div>
</div>

<div class="arrow-down">
  <svg class="icon icon-arrow-down2">
    <use xlink:href="assets/symbol-defs.svg#icon-arrow-down2"></use>
  </svg>
</div>

<owl-carousel-o [options]="hiredStudentsOptions">
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/11.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          CELAL AKÇELİK
        </div>
        <div class="company">
          Zack.ai
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/3.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          ANIL DEMİR
        </div>
        <div class="company">
          Migros IT
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/7.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          HÜSNÜ TAŞ
        </div>
        <div class="company">
          Ecodation
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/2.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          ELZEM NUR DEMİR
        </div>
        <div class="company">
          Aydınlı Grup
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/8.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          SAMED BİÇER
        </div>
        <div class="company">
          Ecodation
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/4.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          EDANUR CAN
        </div>
        <div class="company">
          Emin Şirketler Grubu
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/5.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          NİHAN ERTAŞ
        </div>
        <div class="company">
          BTC Bilişim
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/6.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          ALAN H.
        </div>
        <div class="company">
          Rota Teknoloji Hizmetleri
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/9.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          ERAY ÖZKAN
        </div>
        <div class="company">
          BBS
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/10.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          AHMET FATİH AYAZ
        </div>
        <div class="company">
          BBS
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/12.jpg">
      <div class="hired-students-card-info">
        <div class="name">
          DENİZ GELİR
        </div>
        <div class="company">
          Hamurlabs
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="hired-students-card">
      <img src="https://api.ecodation.com/ise_giren_ogrenciler/14.jpeg">
      <div class="hired-students-card-info">
        <div class="name">
          ALİ ONURCAN İREY
        </div>
        <div class="company">
          Qualist Technology
        </div>
      </div>
    </div>
  </ng-template>
</owl-carousel-o> -->


<!-- <div class="badge">
  Staj
</div>

<div class="title">
  <div class="title-text">
    Geçen Dönem <span>Stajyerlerimiz</span>
  </div>
  <div class="title-description">
    Kendilerine güvendiler, başarılı oldular
  </div>
</div>

<div class="arrow-down">
  <svg class="icon icon-arrow-down2">
    <use xlink:href="assets/symbol-defs.svg#icon-arrow-down2"></use>
  </svg>
</div>

<owl-carousel-o [options]="internOptions">
  <ng-template carouselSlide>
    <div class="intern-card">
      <div class="intern-card-photo">
        <img src="https://api.ecodation.com/stajyerler/1.jpg">
      </div>
      <div class="intern-card-info">
        <div class="name">
          Gizem Öçal
        </div>
        <div class="university">
          Medipol Üniversitesi
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="intern-card">
      <div class="intern-card-photo">
        <img src="https://api.ecodation.com/stajyerler/2.jpg">
      </div>
      <div class="intern-card-info">
        <div class="name">
          Ebru Uzun
        </div>
        <div class="university">
          Yıldız Teknik Üniversite
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="intern-card">
      <div class="intern-card-photo">
        <img src="https://api.ecodation.com/stajyerler/3.jpg">
      </div>
      <div class="intern-card-info">
        <div class="name">
          Egecem Zengin
        </div>
        <div class="university">
          Marmara Üniversitesi
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="intern-card">
      <div class="intern-card-photo">
        <img src="https://api.ecodation.com/stajyerler/4.jpg">
      </div>
      <div class="intern-card-info">
        <div class="name">
          Ceren Başdan
        </div>
        <div class="university">
          Yıldız Teknik Üniversitesi
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="intern-card">
      <div class="intern-card-photo">
        <img src="https://api.ecodation.com/stajyerler/5.jpg">
      </div>
      <div class="intern-card-info">
        <div class="name">
          Barış Kısadal
        </div>
        <div class="university">
          Yıldız Teknik Üniversitesi
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template carouselSlide>
    <div class="intern-card">
      <div class="intern-card-photo">
        <img src="https://api.ecodation.com/stajyerler/6.jpg">
      </div>
      <div class="intern-card-info">
        <div class="name">
          Ahmet Kayra Yavuz
        </div>
        <div class="university">
          Marmara Üniversitesi
        </div>
      </div>
    </div>
  </ng-template>
</owl-carousel-o> -->

<!-- <div class="title">
  <div class="title-text">
    Staj Garantili <span>Eğitimler</span>
  </div>
</div>

<div class="application-button">
  <button (click)="navigateApplicationPage()" mat-raised-button>Eğitime Katıl, Stajını Tamamla</button>
</div> -->

<div class="badge">
  Temsilci
</div>

<div class="title">
  <div class="title-text">
    Üniversite <span>Temsilcilerimiz</span>
  </div>
  <div class="title-description">
    Kendilerine güvendiler, başarılı oldular
  </div>
</div>

<div class="arrow-down">
  <svg class="icon icon-arrow-down2">
    <use xlink:href="assets/symbol-defs.svg#icon-arrow-down2"></use>
  </svg>
</div>

<div class="universities">
  <div class="universities-card">
    Abdullah Gül Üniversitesi
  </div>
  <div class="universities-card">
    Afyon Kocatepe Üniversitesi
  </div>
  <div class="universities-card">
    Akdeniz Üniversitesi
  </div>
  <div class="universities-card">
    Ankara Üniversitesi
  </div>
  <div class="universities-card">
    Bursa Uludağ Üniversitesi
  </div>
  <div class="universities-card">
    Ege Üniversitesi
  </div>
  <div class="universities-card">
    Galatasaray Üniversitesi
  </div>
  <div class="universities-card">
    Gazi Üniversitesi
  </div>
  <div class="universities-card">
    Hacettepe Üniversitesi
  </div>
  <div class="universities-card">
    İstanbul Üniversitesi
  </div>
  <div class="universities-card">
    İstanbul Teknik Üniversitesi
  </div>
  <div class="universities-card">
    İstanbul Medeniyet Üniversitesi
  </div>
  <div class="universities-card">
    Kocaeli Üniversitesi
  </div>
  <div class="universities-card">
    Marmara Üniversitesi
  </div>
  <div class="universities-card">
    Orta Doğu Teknik Üniversitesi
  </div>
  <div class="universities-card">
    Selçuk Üniversitesi
  </div>
  <div class="universities-card">
    Yalova Üniversitesi
  </div>
  <div class="universities-card">
    Yıldız Teknik Üniversitesi
  </div>
</div>

<div class="application-button">
  <button (click)="openDelegateForm()" mat-raised-button>Temsilci Başvuru</button>
</div>

<app-footer></app-footer>
