<h2 mat-dialog-title>Ödev Ekle</h2>
<div mat-dialog-content>
  <div mat-form-field class="example-form-field" appearance="fill">
    <div mat-label class="mat-label">Ödevin Başlığı</div>
    <div >
    <input matInput type="text" [(ngModel)]="title" class="mat-input">
<div>
  <div class="scton">

    <p>Son gönderim tarihi:</p>
    <input matInput type="date" [(ngModel)]="date" (change)="convertDate()" data-date-format="YYYY MM DD" class="mat-input">
  </div>

</div>

    </div>
  </div>


  <div mat-form-field class="example-form-field" appearance="fill">
    <div mat-label class="mat-label">Ödevin Google Drive Linki (Öğrencinin ödevi görüntüleyebilmesi için)</div>
    <input matInput type="text" [(ngModel)]="drive_url" class="mat-input">

  </div>

  <div mat-form-field class="example-form-field" appearance="fill">
    <div mat-label class="mat-label">Ödevin Google Form Linki (Öğrencinin ödevi yükleyebilmesi için)</div>
    <input matInput type="text" [(ngModel)]="form_url" class="mat-input">

  </div>

  <div mat-form-field appearance="fill" class="select">
    <div mat-label class="mat-label">Ödevi Eklemek İstediğiniz Eğitim</div>
    <select [(ngModel)]="lesson" chan matNativeControl
      [disabled]="disableSelect.value" class="mat-select">
      <option value="" selected></option>
      <option class="option" value="{{lesson.id}}" *ngFor="let lesson of
        lessons"><div>{{lesson.name }} </div>  <div>({{ lesson.start_date
          | slice:0:10}})</div></option>

    </select>
  </div>

  <div class="mat-buttons">
    <button mat-button  class="mat-button" (click)="openGoogleDrive()">Ödev için Google Form Oluştur</button>
    <button mat-button mat-dialog-close class="mat-button">Vazgeç</button>

    <button *ngIf="isLoading==false" mat-dialog-close mat-button class="mat-button"
      [disabled]="!title||!drive_url||!form_url||!lesson||!date" (click)="addHomework()">Ekle</button>
  </div>

  <br><br><br>


</div>
