<header class="header">
  <div (click)="navigateTo('https://akademi.ecodation.com')" class="header-logo">
    <img src="https://ecodation.s3.amazonaws.com/G%C3%9CNCEL%20DOSYALAR/ecodation%20logo%20beyaz.png" alt="ecodation-web-logo">
  </div>

  <div class="header-button" *ngIf="!authState">
    <button class="login-btn" mat-raised-button routerLink="/login">Giriş Yap</button>
    <button mat-raised-button routerLink="/register">Üye Ol</button>
    <!-- <button mat-raised-button (click)="navigateTo('https://form.ecodation.com')">Ön Başvuru Yap</button> -->
  </div>

  <div class="header-button" *ngIf="authState">
    <div class="profile">
      <div class="photo">
        <img [src]="user.photo">
      </div>
      <div
        [ngStyle]="{'border-bottom-width.px': isOpen ? '0' : '1', 'border-bottom-right-radius.px': isOpen ? '0' : '8'}"
        (click)="toggle()" class="dropdown-menu-container">
        {{ user.name }}
        <div [@dropdownAnimation]="isOpen ? 'open' : 'close'" class="dropdown-menu-item-container">
          <div routerLink="/taken-educations" class="dropdown-menu-item">
            Eğitimlerim
          </div>
          <!-- <div routerLink="/panel" class="dropdown-menu-item">
            Panel
          </div> -->
          <div (click)="logout()" class="dropdown-menu-item">
            Çıkış
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
