<div #dialogContent class="nav-bar">
  <div  class="nav-bar-title animate__animated animate__backInLeft">
    <div  *ngIf="!isChangingTitle" style="display: flex;justify-content: center;">
      <h2>{{data.title}}</h2>
      <mat-icon (click)="changeChangingTitle()">mode_edit</mat-icon>
    </div>

    <div class="animate__animated animate__backInRight" *ngIf="isChangingTitle" style="display: flex;justify-content: center;">
      <input placeholder="Başlık" style="    background: #f0f0f0;
      margin-right: 20px;
      border-radius: 15px;
      text-align: center;
      border: none;" type="text" [(ngModel)]="newQuizTitle">
      <button [disabled]="!newQuizTitle" [ngClass]="{'disabled-button': true}"  style="    margin-right: 20px;
      border-radius: 7px;
      border: none;
      text-align: center;
      background: #039f1f;
      color: white;
      font-weight: 700;" (click)="updateQuizTitle(newQuizTitle)">Değiştir</button>
      <p  style="color: red;
      font-weight: 600;
      align-items: center;
      display: flex;
      margin-top: 8px;
  " (click)="changeChangingTitle()">İptal</p>

    </div>

  </div>


  <div  class="nav-bar-title animate__animated animate__backInLeft">
    <div  *ngIf="!isChangingTime" style="display: flex;justify-content: center;">
      <h2>Süre: {{data.time }}dk</h2>
      <mat-icon (click)="changeChangingTime()">mode_edit</mat-icon>
    </div>

    <div class="animate__animated animate__backInRight" *ngIf="isChangingTime" style="display: flex;justify-content: center;">
      <input placeholder="Süre (dk.)" style="    background: #f0f0f0;
      margin-right: 20px;
      border-radius: 15px;
      text-align: center;
      border: none;" type="text" [(ngModel)]="newQuizTime">
      <button [disabled]="!newQuizTime" [ngClass]="{'disabled-button': true}"  style="    margin-right: 20px;
      border-radius: 7px;
      border: none;
      text-align: center;
      background: #039f1f;
      color: white;
      font-weight: 700;" (click)="updateQuizTime()">Değiştir</button>
      <p  style="color: red;
      font-weight: 600;
      align-items: center;
      display: flex;
      margin-top: 8px;
  " (click)="changeChangingTime()">İptal</p>

    </div>

  </div>
  <div class="nav-bar-buttons">
    <button style="background-color:#2545f6;color: white;" mat-raised-button  mat-dialog-close>
     Kaydet & Kapat
    </button>

  </div>
</div>
<br><br><br><br><br>

<div *ngIf="isAdding"(click)="changeAdding()"  class="add-question">
  <button mat-raised-button style="color: white;background-color: red;font-weight: 500;">İptal</button>
 <!-- <mat-icon class="mat-18">cancel</mat-icon> -->
  </div>
<div class="dialog center-dialog">


<div  class="question animate__animated animate__backInLeft"  *ngIf="isAdding">



  <textarea class="textArea" [(ngModel)]="title" placeholder="Soruyu buraya girin"></textarea>

  <p style="    margin-top: 29px;
  font-weight: 500;
">Doğru olan şıkkın yanındaki kutucuğu işaretleyin ve soruyu kaydedin</p>
<div class="options" >
<div class="option">
  <input type="checkbox" [(ngModel)]="correctOptionA" (change)="selectOption('A')">

      <input class="option-input" [(ngModel)]="titleA" type="text" placeholder="A şıkkını buraya girin">

     </div>
     <div class="option">
      <input type="checkbox" [(ngModel)]="correctOptionB" (change)="selectOption('B')">

      <input class="option-input" [(ngModel)]="titleB" type="text" placeholder="B şıkkını buraya girin">

     </div>
     <div class="option">
      <input  type="checkbox"  [(ngModel)]="correctOptionC" (change)="selectOption('C')">

      <input class="option-input" [(ngModel)]="titleC" type="text" placeholder="C şıkkını buraya girin">

     </div>
     <div class="option">
      <input type="checkbox" [(ngModel)]="correctOptionD" (change)="selectOption('D')">

      <input class="option-input" [(ngModel)]="titleD" type="text" placeholder="D şıkkını buraya girin">

     </div>
     <div style="display: flex;justify-content: end;">
  <button [disabled]="!title||!titleA||!titleB||!titleC||!titleD||!correctOption" (click)="addNewQuestion(title,correctOption,titleA,titleB,titleC,titleD)"mat-raised-button color="primary" >Kaydet</button>
</div>

    </div>

</div>
</div>
<!----->

<div class="dialog center-dialog">


  <div  class="question animate__animated animate__backInLeft"  *ngIf="isUpdating">
<div style="display: flex;justify-content: space-between;">
  <h3>Soru{{ selectedQuestion.id}}) </h3>
  <p style="color: red; font-weight: 500;" *ngIf="user.role_id == 2" (click)="changeUpdating()"  >Vazgeç</p>

</div>

    <textarea class="textArea" [(ngModel)]="title" placeholder="Soruyu buraya girin"></textarea>

    <p style="    margin-top: 29px;
    font-weight: 500;
  ">Doğru olan şıkkın yanındaki kutucuğu işaretleyin ve soruyu kaydedin</p>
  <div class="options" >
  <div class="option">
    <input type="checkbox" [(ngModel)]="correctOptionA" (change)="selectOption('A')">

        <input class="option-input" [(ngModel)]="titleA" type="text" placeholder="A şıkkını buraya girin">

       </div>
       <div class="option">
        <input type="checkbox" [(ngModel)]="correctOptionB" (change)="selectOption('B')">

        <input class="option-input" [(ngModel)]="titleB" type="text" placeholder="B şıkkını buraya girin">

       </div>
       <div class="option">
        <input  type="checkbox"  [(ngModel)]="correctOptionC" (change)="selectOption('C')">

        <input class="option-input" [(ngModel)]="titleC" type="text" placeholder="C şıkkını buraya girin">

       </div>
       <div class="option">
        <input type="checkbox" [(ngModel)]="correctOptionD" (change)="selectOption('D')">

        <input class="option-input" [(ngModel)]="titleD" type="text" placeholder="D şıkkını buraya girin">

       </div>
       <div style="display: flex;justify-content: end;">
    <button [disabled]="!title||!titleA||!titleB||!titleC||!titleD||!correctOption" (click)="!isUpdating ? addNewQuestion(title,correctOption,titleA,titleB,titleC,titleD):updateQuestion()"mat-raised-button color="primary" >Kaydet</button>
  </div>

      </div>

  </div>
  </div>

<hr *ngIf="isAdding" class="divider">



<div *ngIf="!isAdding && !isUpdating"(click)="changeAdding()"  class="add-question">
<button mat-raised-button color="#2545f6" style="    display: flex;
align-items: center;     color: black;
    background-color: white;">Soru Ekle </button>


</div>


<div class="dialog center-dialog">
  <div class="question animate__animated animate__backInUp" *ngFor="let question of questions let index=index">
    <div style="display: flex;justify-content: space-between; align-items: center;">
      <div>
        <h3 class="title" >Soru {{index + 1}}) <br> <span style="font-weight: 400;"> {{question.title}}  </span> </h3>

      </div>

      <div >
        <mat-icon *ngIf="!isAdding" (click)="changeUpdating(question)">mode_edit</mat-icon>
<br><br>

        <p style="color: red; font-weight: 500;" *ngIf="user.role_id == 2 &&!isAdding" (click)="deleteQuestion(question.id)"  >Sil</p>

      </div>

    </div>

<div class="options"  >
  <div class="option" style="{{question.correct_option == 'A' ?'color:green;font-weight:bold':'color:black'}}" >

        <h2>{{question.options[0].title}}) </h2>
        <p>{{question.options[0].text}} </p>
       </div>

       <div class="option"  style="{{question.correct_option == 'B' ?'color:green;font-weight:bold':'color:black'}}">


        <h2>{{question.options[1].title}})</h2>
        <p>{{question.options[1].text}} </p>
       </div>
       <div class="option"  style="{{question.correct_option == 'C' ?'color:green;font-weight:bold':'color:black'}}">

        <h2>{{question.options[2].title}})</h2>
        <p>{{question.options[2].text}} </p>
       </div>
       <div class="option"  style="{{question.correct_option == 'D' ?'color:green;font-weight:bold':'color:black'}}">

        <h2>{{question.options[3].title}})</h2>
        <p>{{question.options[3].text}}</p>
       </div>
      </div>

</div>
</div>




