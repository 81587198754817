import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MainService } from '../core/services/main.service';
import { AuthService } from '../core/services/auth.service';
import { User } from '../core/models/user.model';
import { DatePipe } from '@angular/common';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-add-homework-dialog',
  templateUrl: './add-homework-dialog.component.html',
  styleUrls: ['./add-homework-dialog.component.scss']
})
export class AddHomeworkDialogComponent implements OnInit {
  title;
  form_url;
  drive_url;
  user:any;
  date;
  lesson;
  lessons:any;
  isLoading:boolean=false;
  mode: ProgressSpinnerMode = 'indeterminate';

  disableSelect = new FormControl(false);
  endDate;

  constructor(private mainService:MainService,
    private authService:AuthService,
    public datepipe: DatePipe,
    private _snackBar:MatSnackBar,

    ) { }

    openSnackBar() {
      this._snackBar.open('Ödev Başarıyla Eklendi','Tamam', {
        duration: 5 * 1000,
        panelClass: ['green-snackbar', 'login-snackbar']
            });
    }


  ngOnInit(): void {

    this.authService.currentUser.subscribe((user: User) => {
      this.user = user;
      console.log(this.user);
    });
    this.getLessonsByLecture();
  }

  addHomework(){
    this.isLoading=true;
    const data={
    "operation_type":"insert",
    "service_type":"add_homework",
    "endDate":this.endDate,
    "form_url":this.form_url,
    "drive_url":this.drive_url,
    "title":"title",
    "education_id":parseInt( this.lesson),
    "teacher_id":parseInt( this.user.id)
    }
    this.mainService.addHomework(data).subscribe(res=>{
console.log(res);
this.isLoading=false;
this.openSnackBar();

    })
  }

  convertDate(){

    this.date =this.datepipe.transform(this.date, 'yyyy-MM-dd');
    this.endDate=this.date
    console.log(this.endDate)
   }
getLessonsByLecture(){
  const data ={
    "operation_type":"select",
    "service_type":"getLessonsByLecture",
    "teacher_id":this.user.id
  }
this.mainService.getLessonsByLecturer(data).subscribe(res=>{
this.lessons=res.results;


});
}
  openGoogleDrive(){
    window.open('https://www.google.com/forms/about/','_blank')
  }

}
