import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { state, trigger, style, transition, animate } from '@angular/animations';
import { MainService } from 'src/app/core/services/main.service';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fade', [
      state('small', style({
        width: '25px',
        height: '25px'
      })),
      state('large', style({
        width: '45px',
        height: '45px'
      })),
      transition('* <=> *', [
        animate(1000)
      ]),
    ])
  ]

})
export class HomeComponent implements OnInit {

  educations: any;
  typeWriterElement: any;
  // The TextArray:
  textArray: any;
  state = 'small';


  constructor(
    private mainService: MainService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    window.open('https://academy.ecodation.com/', '_self');
    this.typeWriterElement = document.getElementById('typewriter');
    console.log(this.typeWriterElement);

    /*         this.mainService.getAllEducations()
              .subscribe(data => {
                this.educations = data;
                console.log(this.educations);
              });

            this.activatedRoute.data.subscribe((data: []) => {
              console.log(data);
            }); */
    this.activatedRoute.data.subscribe(data => {

      this.educations = data.educations;
      console.log(this.educations)
      this.textArray = [...new Set(data.educations.map(item => `${item.name} `))];

      this.startWriter(0);
    });
  }

  educationOptions: OwlOptions = {
    dots: true,
    loop: true,
    margin: 0,
    stagePadding: 10,
    autoWidth: true,
    center: true,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 3
      }
    }
  }

  hiredStudentsOptions: OwlOptions = {
    dots: true,
    loop: true,
    // margin: 40,
    // center: true,
    stagePadding: 10,
    autoWidth: true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 5
      }
    }
  }

  internOptions: OwlOptions = {
    dots: true,
    loop: true,
    margin: 0,
    // center: true,
    stagePadding: 10,
    autoWidth: true,
    responsive: {
      0: {
        items: 2
      },
      768: {
        items: 5
      }
    }
  }
  // The typewriter element




  // function to generate the backspace effect
  delWriter(text, i, cb) {
    if (i >= 0) {
      this.typeWriterElement.innerHTML = text.substring(0, i--);
      // generate a random Number to emulate backspace hitting.
      var rndBack = 10 + Math.random() * 100;
      setTimeout(() => {
        this.delWriter(text, i, cb);
      }, rndBack);
    } else if (typeof cb == 'function') {
      setTimeout(cb, 500);
    }
  }

  // function to generate the keyhitting effect
  typeWriter(text, i, cb) {
    if (i < text.length) {
      this.typeWriterElement.innerHTML = text.substring(0, i++);
      // generate a random Number to emulate Typing on the Keyboard.
      var rndTyping = 250 - Math.random() * 100;
      setTimeout(() => {
        this.typeWriter(text, i++, cb)
      }, rndTyping);
    } else if (i === text.length) {
      setTimeout(() => {
        this.delWriter(text, i, cb)
      }, 500);
    }
  };

  // the main writer function
  startWriter(i) {
    if (typeof this.textArray[i] == "undefined") {
      setTimeout(() => {
        this.startWriter(0)
      }, 500);
    } else if (i < this.textArray[i].length) {
      this.typeWriter(this.textArray[i], 0, () => {
        this.startWriter(i + 1);
      });
    }
  };

  onDone($event) {
    // call this function at the end of the previous animation.
    // run it as many time as defined
    this.state = this.state === 'small' ? 'large' : 'small';
  }

  openDelegateForm() {
    window.open("https://forms.gle/Qqq5GMXVfoshy4S67", "_blank");
  }

  navigateApplicationPage() {
    window.open('https://form.ecodation.com', '_self');
  }

}
