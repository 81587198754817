<table *ngIf="!isLoading">
  <tr>
    <th>Sıra</th>
    <th>Ad Soyad</th>
    <th>Doğru</th>
    <th>Yanlış</th>
    <th>Boş</th>
    <th>Puan</th>



  </tr>
  <tr *ngFor="let student of sortStudentsByPoint(data); let i = index">
    <td>{{i+1}}</td>
    <td>{{student.name}}</td>
    <td>{{calcAnswerStats(student.id).correct}}</td>
    <td>{{calcAnswerStats(student.id).incorrect}}</td>
    <td>{{calcAnswerStats(student.id).empty}}</td>
    <td style="color: red;font-weight: 600;" *ngIf="calcAnswerStats(student.id).empty == questions.length">Tamamlanmadı</td>
    <td *ngIf="calcAnswerStats(student.id).empty != questions.length">{{  calcAnswerStats(student.id).point | number:'1.2-2' }}</td>



  </tr>

</table>

<div *ngIf="isLoading" style="display: grid;justify-content: center;">
  <mat-spinner class="matspinner"></mat-spinner>
  <br><br>
    <h2>Yükleniyor...</h2>
  </div>
