import { Component, Inject, OnInit, } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { MainService } from 'src/app/core/services/main.service';

@Component({
  selector: 'app-quiz-dialog-component',
  templateUrl: './quiz-dialog-component.component.html',
  styleUrls: ['./quiz-dialog-component.component.scss'],
})
export class QuizDialogComponentComponent implements OnInit {
user:User
isResolved:boolean;
currentDateTime: Date;
formattedDate:string;
isLoading=false;
isResult ;
questions:any;
answers:any;
trueCount:any=0;
falseCount:any=0;
emptyCount:any=0;
delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
async getQuestions(){
const data = {
  "operation_type":"select",
  "service_type":"quiz_questions",
  "quiz_id":this.data.data.id
  }


  this.mainService.getQuizQuestions(data).subscribe(async res => {

this.questions =await res


for (let i = 0; i < this.questions.length; i++) {

this.questions[i].options =await JSON.parse(this.questions[i].options);

}





  });
}

async getAnswers(){
const data2 = {
  "operation_type":"select",
  "service_type":"user_quiz_answers",
  "user_id":this.user.id,
  "quiz_id":this.data.data.id
}

this.mainService.getUserQuizAnswers(data2).subscribe(async res=>{
this.answers= await res;

await this.delay(2000);
for (let i = 0; i < this.questions.length; i++) {
const questionId = this.questions[i].id;


const answer = this.answers.find(answer => answer.question_id === questionId);
if (answer) {
  if (this.questions[i].correct_option === answer.user_answer) {
    this.trueCount++;
  } else {
    this.falseCount++;
  }
} else {
  this.emptyCount++;
}
}




})
await this.delay(2000);

this.isLoading = false;
}



constructor(@Inject(MAT_DIALOG_DATA) public data :any,private authService:AuthService,private mainService:MainService) { }

  ngOnInit(): void {


if (this.data.id == 'result'){
  this.isResult = true
   this.getQuestions()

  this.getAnswers()
}else {
  this.isResult =false;
}
    this.isLoading= true
    this.currentDateTime = new Date();
    const year = this.currentDateTime.getFullYear().toString();
    const month = (this.currentDateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = this.currentDateTime.getDate().toString().padStart(2, '0');
    const hours = this.currentDateTime.getHours().toString().padStart(2, '0');
    const minutes = this.currentDateTime.getMinutes().toString().padStart(2, '0');
    const seconds = this.currentDateTime.getSeconds().toString().padStart(2, '0');

    this.formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    this.authService.currentUser.subscribe((user: User) => {
      this.user = user;

const data =  {
  "operation_type":"select",
  "service_type":"check_user_trick",
  "user_id":this.user.id,
  "quiz_id":this.data.data.id
  }
  this.mainService.checkUserQuizBefore(data).subscribe(res=>{

    if(res == true){
      //  quiz tamamlanmış

      this.isResolved = true

     }
     else if (res == false){
this.isResolved = false


     }
     else {
      //quizi tamamlamamış ama süresi bitmiş yine tamamlandı uyarısı vermen lazım
      const startTime = new Date(this.formattedDate);
      const endTime = new Date(res);

      const diffMilliseconds = endTime.getTime() - startTime.getTime();
      const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));


if (diffMinutes<=0) {
  this.isResolved = true


}

  }
}
)

    });

    this.getQuestions()

  this.getAnswers()
  }

  start(){
    const data2 = {
      "operation_type":"insert",
            "service_type":"create_user_quiz_info",
            "user_id":this.user.id,
            "quiz_id":this.data.data.id,
          "start_time":this.formattedDate

    }
    this.mainService.addUserAnswerToQuiz(data2).subscribe(res=>{

    })
  }
}
