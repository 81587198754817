import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openSocialMedia(type) {
    if (type == 'facebook') {
      window.open('https://www.facebook.com/ecodation/?ref=bookmarks', '_blank');
    } else if (type == 'instagram') {
      window.open('https://www.instagram.com/ecodation/?hl=tr', '_blank');

    } else if (type == 'linkedin') {
      window.open('https://www.linkedin.com/company/ecodation/?viewAsMember=true', '_blank');

    }
  }

}
