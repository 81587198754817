import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(
    private apiService: ApiService
  ) { }
  getAllLessons(data:any): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }


  mainService(data: any): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }


  checkDiscountCode(data: { operation_type: string; service_type: string; code: any; education_id: any; }): Observable<any> {
    return this.apiService.post(data)
    .pipe(map(response => response));
  }

  getDelegates(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }
  sendEduInfoMail(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getDelegateOperationDetail(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getMailGroupList(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getEduClassList(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getAffirmativeApplicationCountByEdu(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getPriceByAffirmativeApplication(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getAllEducations(): Observable<any> {
    const data = {
      operation_type: 'select',
      service_type: 'lessons'
    }
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }
findCerticifate(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
getDiscountCodes(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
addDiscountCode(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
deleteDiscountCode(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
announcQuizResults(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
deleteQuiz(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
completeUserQuiz(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
chechUserQuizTrick(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
addNewQuestionToQuiz(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
deleteQuestion(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
updateQuizQuestion(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
updateQuizInfo(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
updateQuizTime(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
updateQuizTitle(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
getQuizQuestions(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
getQuizAnswers(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
getUserQuizAnswers(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
addUserAnswerToQuiz(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
checkUserQuizBefore(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
getUserEduStudents(data): Observable<any> {
  return this.apiService.post(data)
    .pipe(map(response => response));
}
  getEducation(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }
  getParticipations(data):Observable<any>{
    return this.apiService.post(data).pipe(map(response => response.data));
  }
  getLessonsStudent(data):Observable<any>{
    return this.apiService.post(data).pipe(map(response => response.data));
  }

  getLessonsByLecturer(data):Observable<any>{
    return this.apiService.post(data).pipe(map(response => response.data));
  }

  getEducationVideos(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  insertEducationVideo(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  updateEducationVideo(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  deleteEducationVideo(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  getEducationGroups(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  getApplication(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  insertEducation(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  updateEducation(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  updateEduPlan(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  insertEduPlan(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  deleteEduPlan(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  updateEduDate(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  insertEduDate(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  deleteEduDate(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  getLessonsByType(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  getLecturers(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  getEducationsViaGroup(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  getTakenEducations(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  getBankAccounts(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  getInstallments(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  updateMeetingRoomId(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  updateEduParticipation(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  getTakenEducationsDetail(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  getLive(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  getRecording(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  getEducationsPanel(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response.data));
  }

  doApplication(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  eftTransfer(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  addLecturer(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  addQuiz(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  createQuiz(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getQuiz(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getReport(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  addHomework(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  getHomework(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  takeParticipation(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  sendPasswordReminderMail(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  sendResetPasswordMail(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }
  changePassword(data):Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  deleteLecturer(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  deleteLesson(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => response));
  }

  uploadFile(file: File): Observable<any> {
    return this.apiService.uploadFile(file)
      .pipe(map(response => response));
  }

  insertContract(formData: FormData): Observable<any> {
    return this.apiService.insertContract(formData)
      .pipe(map(response => response));
  }
  /*
    purchase(data): Observable<any> {
      return this.apiService.purchase(data)
        .pipe(map(response => response));
    } */
}
