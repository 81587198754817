import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import { User } from '../models/user.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authenticationState = new BehaviorSubject<boolean>(false);
  public currentUser = new BehaviorSubject<User>({} as User);

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private router: Router
  ) { }

  populate() {
    const data = {
      operation_type: 'select',
      service_type: 'user_populate',
      token: this.jwtService.getToken()
    }
    if (this.jwtService.getToken()) {
      this.apiService.post(data)
        .subscribe(
          res => this.setAuth(res.data),
          err => this.logout()
        );
    } else {
      this.logout()
    }
  }

  login(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => {
        if (!response.error) {
          this.setAuth(response.data);
        }
        return response;
      }));
  }

  register(data): Observable<any> {
    return this.apiService.post(data)
      .pipe(map(response => {
        if (!response.error) {
          this.setAuth(response.data);
        }
        return response;
      }));
  }

  setAuth(user: User) {
    this.jwtService.saveToken(user._token);
    this.currentUser.next(user);
    this.authenticationState.next(true);
  }

  logout() {
    this.jwtService.destroyToken();
    this.currentUser.next({} as User);
    this.authenticationState.next(false);
  }
}
