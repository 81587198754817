import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { User } from 'src/app/core/models/user.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('dropdownAnimation', [
      state('open', style({
        opacity: '1',
        top: '100%',
        height: 'auto',
        overflow: 'auto'
      })),
      state('close', style({
        opacity: '0',
        top: '0',
        height: '0',
        overflow: 'hidden'
      })),
      transition('open => close', [
        animate(150)
      ]),
      transition('close => open', [
        animate(150)
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {

  user: User;
  authState: boolean;
  isOpen = false;

  authStateSubscribe: Subscription;
  currentUserSubscribe: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.authStateSubscribe = this.authService.authenticationState.subscribe(state => {
      this.authState = state;
    });
    this.currentUserSubscribe = this.authService.currentUser.subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnDestroy() {
    this.authStateSubscribe.unsubscribe();
    this.currentUserSubscribe.unsubscribe();

  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  logout() {
    this.authService.logout();
    window.open('https://akademi.ecodation.com','_self')

  }

  navigateTo(url: string) {
    window.open(url, '_self');
  }

}
