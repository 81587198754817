<div *ngIf="isResult==false" class="dialog-container">
  <div *ngIf="isLoading==true">
  <mat-spinner class="matspinner"></mat-spinner>
  <br><br>
    <h2>Yükleniyor...</h2>
  </div>



  <div *ngIf="!isResolved && isLoading==false">
    <h2>{{data.data.title}}</h2>
    <p class="time"><b>Süre:</b> {{data.data.time}} dk</p>
    <p> Süre bitmeden tekrar giriş yapabilirsiniz.Quizi tamamlarsanız ya da süreniz biterse tekrar giriş yapamazsınız</p>
    <br><br>
    <div style="display: flex;justify-content: center;">
      <button [mat-dialog-close]="true" class="rejectButton">Vazgeç</button>
      <button  (click)="start()" [routerLink]="['/taken-educations','/quiz',
      data.id]" [queryParams]="{ id: data.data.id,time:data.data.time }"  [mat-dialog-close]="true" class="startButton" target="_blank">Başla</button>
    </div>

  </div>
 <div *ngIf="isResolved  && isLoading==false ">
  <div *ngIf="isResolved">
    <h2>{{data.title}}</h2>

    <p *ngIf="isLoading==false && isResolved== true" >Quizi tamamladınız. Tekrar giriş yapamazsınız</p>


 </div>
</div>
</div>

<div *ngIf="isResult==true"  class="container">
  <div *ngIf="isLoading" style="display: grid;justify-content: center;color: white;">
    <mat-spinner class="matspinner" ></mat-spinner>
    <br><br>
      <h2 style="color: black;">Yükleniyor...</h2>
    </div>
</div>

<div *ngIf="!isLoading && isResult==true" class="container">
  <button style="background-color:#f31010;color: white;" mat-raised-button  mat-dialog-close>
    Kapat
   </button>
  <div class="quizresultinfo">

    <h3 style="font-weight: bold;">Soru sayısı : {{questions.length}}</h3>
    <h3 style="color: green; font-weight: bold;">Doğru  : {{trueCount}}</h3>
    <h3 style="color: red;font-weight: bold;">Yanlış  : {{falseCount}}</h3>
    <h3 style="font-weight:  bold;">Boş : {{emptyCount}}</h3>
  </div>



  <div class="question" *ngFor="let question of questions; let index=index">
    <div style="display: flex;justify-content: space-between;">
      <div>
        <h3 class="title" > {{index + 1}}) <br> <span style="font-weight: 400;"> {{question.title}}  </span> </h3>

      </div>
      <div>
        <span style="font-size: 16px;font-weight: bold;" *ngIf="!answers[index]?.user_answer"> (Boş) </span>
        <span *ngIf="answers[index]?.user_answer === question.correct_option && answers[index]?.user_answer" style="color: green;font-size: 16px;font-weight: bold;"> (Doğru) </span>
        <span *ngIf="answers[index]?.user_answer !== question.correct_option && answers[index]?.user_answer">
          <span style="color: red;font-size: 16px;font-weight: bold;"> (Yanlış) </span>
          <span style="font-weight: 500;">Senin Cevabın:{{answers[index]?.user_answer}}</span>
        </span>
      </div>
    </div>



    <div class="options" *ngIf="question.type == 'test'">
      <div class="option" [ngStyle]="{ 'color': question.correct_option === 'A' ? 'green' : 'black', 'font-weight': question.correct_option === 'A' ? 'bold' : 'normal' }">
        <h2>{{question.options[0].title}})</h2>
        <p>{{question.options[0].text}}</p>
      </div>

      <div class="option" [ngStyle]="{ 'color': question.correct_option === 'B' ? 'green' : 'black', 'font-weight': question.correct_option === 'B' ? 'bold' : 'normal' }">
        <h2>{{question.options[1].title}})</h2>
        <p>{{question.options[1].text}}</p>
      </div>

      <div class="option" [ngStyle]="{ 'color': question.correct_option === 'C' ? 'green' : 'black', 'font-weight': question.correct_option === 'C' ? 'bold' : 'normal' }">
        <h2>{{question.options[2].title}})</h2>
        <p>{{question.options[2].text}}</p>
      </div>

      <div class="option" [ngStyle]="{ 'color': question.correct_option === 'D' ? 'green' : 'black', 'font-weight': question.correct_option === 'D' ? 'bold' : 'normal' }">
        <h2>{{question.options[3].title}})</h2>
        <p>{{question.options[3].text}}</p>
      </div>
    </div>
  </div>





</div>


