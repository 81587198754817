<footer>
  <section class="footer-container" >
    <div class="address">

      <ul>
        Ar-Ge Ofis:
        <div class="spacer">

        </div>
        <li><p >Yıldız Teknik Üniversitesi Teknopark A1 Blok 1B/13-42 <br> Esenler/İstanbul</p></li>

      </ul>

    </div>
    <div class="europe">

      <ul>
        Avrupa Ofis:
        <div class="spacer">

        </div>
        <li><p >Harju maakond, Tallinn, Estonia Lasnamäe linnaosa, Sepapaja <br> tn 6, 15551 </p></li>

      </ul>
    </div>



    <div class="contact">

      <ul>
        İletişim:
        <div class="spacer">

        </div>
        <li><i class="material-icons md-17">headphones</i><p > +90 212 934 10 84</p></li>
        <li><i class="material-icons md-17">mail</i><p> info@ecodation.com</p></li>
<li>
<a href="https://www.instagram.com/ecodation/">
<svg class="icon">
  <use xlink:href="assets/symbol-defs.svg#icon-instagram"></use>
</svg>

</a>

<a href="https://www.linkedin.com/company/ecodation">

<svg class="icon">
  <use xlink:href="assets/symbol-defs.svg#icon-linkedin2"></use>
</svg>
</a>

<a href="https://www.youtube.com/c/ecodation">  <svg class="icon">
<use xlink:href="assets/symbol-defs.svg#icon-youtube"></use>
</svg></a>

</li>


      </ul>
    </div>

  </section>
</footer>
